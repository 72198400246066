<script setup lang="ts">
import type { ContentfulEntry } from '~/types/contentful'

interface Entry extends ContentfulEntry {
  [key: string]: any
}

const props = defineProps({
  content: {
    type: Array<Entry>,
    required: true,
  },
  selectedSlide: {
    type: Number,
    default: 0,
  },
})

const { content, selectedSlide } = toRefs(props)

// Get the entries from the content picker.
const slides = computed(() => {
  return content.value[0]
})

// Get the active slide index.
const getSelectedSlide = computed(() => {
  if (selectedSlide.value > 0 && selectedSlide.value <= slides.value.length) {
    // truncate the value to an integer if it's a decimal.
    return Math.trunc(selectedSlide.value - 1)
  } else if (selectedSlide.value > slides.value.length) {
    return slides.value.length - 1
  }
  return 0
})
</script>

<template>
  <SkyCarousel
    class="WidgetHeroCarousel"
    navigation
    pagination
    grouped-nav-controls
    loop
    :model-value="getSelectedSlide"
  >
    <ContentfulContent
      v-for="slide in slides"
      :key="slide.id"
      :entries="[slide]"
    ></ContentfulContent>
  </SkyCarousel>
</template>

<style lang="scss">
.WidgetHeroCarousel {
  background-color: var(--surface-color-frost);
  padding-bottom: var(--spacing-3x);

  --text-color-action-primary: var(--text-color-action-secondary);
  --text-color-action-dark: var(--text-color-action-secondary);
  --text-color-action-dark-hover: var(--text-color-action-secondary-hover);

  .SkyCarousel_Prev,
  .SkyCarousel_Next,
  .SkyCarousel_Pagination {
    margin-top: var(--spacing-3x);
  }
}
</style>
