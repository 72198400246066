<script setup lang="ts">
import type { ContentfulEntry } from '~/types/contentful'
import { ROUTES } from '~/constants/routes'

/*
 * The Visitor Home Page
 * This page is responsible for the Grove homepage for site visitors who have
 * not checked out yet.
 */

// Set the page meta data
definePageMeta({
  showNotificationBar: true,
  // Setting this to true sets the main content pane to a blank slate without
  // container styles. This allows the page to accommodate content that is flush
  // to header, footer, or edges of the main pane.
  containsFlushContent: true,
  middleware: () => {
    if (process.server) {
      return
    }
    const { hasPlacedOrder, id } = useCustomer()
    const maybeRedirect = async () => {
      try {
        await resolveRef({
          ref: id,
          timeout: 5000,
        })
        if (hasPlacedOrder.value) {
          navigateTo(
            { path: ROUTES.memberHome.to, query: useRoute().query },
            { external: true }
          )
        }
      } catch (error) {
        const { $sentry } = useNuxtApp()
        $sentry.captureMessage('Could not fetch customer.', 'warning')
      }
    }
    maybeRedirect()
  },
})

useHead({
  title: 'Cleaning & Household Products for a Sustainable Home',
  meta: [
    {
      name: 'description',
      content:
        'Grove Collaborative delivers home essentials, including household cleaning, personal care, beauty, and pet products, for a more eco-friendly and sustainable lifestyle.',
    },
  ],
})

const activeCarouselSlide = ref(0)

// Contain the components that should always be full width, listed by contentTypeId or slug.
const FULL_WIDTH_COMPONENTS = [
  'hero-carousel',
  'imageCtaHero',
  'basicValuePropsWithHeaderImage',
]
const renderFullWidthAlways = (entry: ContentfulEntry) => {
  return (
    FULL_WIDTH_COMPONENTS.includes(entry.contentTypeId) ||
    FULL_WIDTH_COMPONENTS.includes(entry.slug)
  )
}
</script>

<template>
  <NuxtLayout>
    <ContentDoc>
      <template #default="{ doc }">
        <div class="HomePage">
          <SkyPage class="HomePage_CTAHeroBlock">
            <SkyFullSpan
              :key="doc.content[0].id"
              :small="renderFullWidthAlways(doc.content[0])"
              :medium="renderFullWidthAlways(doc.content[0])"
              :large="renderFullWidthAlways(doc.content[0])"
            >
              <WidgetHeroCarousel
                v-if="doc.content[0].slug === 'hero-carousel'"
                :selected-slide="activeCarouselSlide"
                :content="[doc.content[0]?.content]"
                data-test-id="hero-carousel"
              />
              <ContentfulContent
                v-else
                :entries="[doc.content[0]]"
              ></ContentfulContent>
            </SkyFullSpan>
            <SkyFullSpan small medium large>
              <WidgetCategoryCarousel data-test-id="category-carousel" />
            </SkyFullSpan>
          </SkyPage>
          <SkyPage>
            <SkyFullSpan
              v-for="entry in doc.content.slice(1)"
              :key="entry.id"
              :small="
                renderFullWidthAlways(entry) ||
                entry.contentTypeId === 'basicProductCarousel'
              "
              :medium="renderFullWidthAlways(entry)"
              :large="renderFullWidthAlways(entry)"
            >
              <ContentfulContent :entries="[entry]" />
            </SkyFullSpan>
          </SkyPage>
        </div>
      </template>
      <template #not-found>
        {{ showErrorPage('No content found') }}
      </template>
    </ContentDoc>
  </NuxtLayout>
</template>

<style lang="scss">
.HomePage {
  // For the image cta hero and category carousel, take out the bottom row gap applied on each SkyFullSpan element by SkyPage.
  // Some temporary hacks that can be refined later.

  margin-bottom: var(--spacing-10x);
  min-height: 95vh;

  &_CTAHeroBlock {
    row-gap: 0;
    margin-bottom: var(--spacing-6x);
  }
}
</style>
