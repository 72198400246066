<script>
import { SkyImage, SkyLink } from '@groveco/skylight'
import { GROVE_CATEGORIES } from '~/constants'

export default {
  components: {
    SkyImage,
    SkyLink,
  },
  data: () => ({
    categoriesToDisplay: GROVE_CATEGORIES,
  }),
}
</script>

<template>
  <section class="CategoryCarousel">
    <ol class="CategoryCarousel_Slides">
      <li
        v-for="(category, index) in categoriesToDisplay"
        :key="category.id"
        :position="index"
      >
        <SkyLink
          tag="a"
          :to="category.link"
          class="CategoryTile"
          data-test-id="category-tile-link"
          unstyled
        >
          <div ref="imageContainer" class="CategoryTile_ImageContainer">
            <SkyImage
              class="CategoryTile_Image"
              :src="category.srcImage"
              alt=""
            />
            <div class="CategoryTile_CategoryName">
              {{ category.name }}
            </div>
          </div>
        </SkyLink>
      </li>
    </ol>
  </section>
</template>

<style lang="scss">
.CategoryCarousel {
  display: grid;

  *,
  *::before,
  *::after {
    box-sizing: unset;
  }

  @include for-xlarge-up {
    @include container-large;
  }

  @include for-medium-up {
    height: 142px;
  }

  &_Slides {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(117px, 1fr));
    grid-auto-flow: column;
    margin: 0;
    justify-items: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    list-style-type: none;
    padding-inline-start: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    @include for-small-down {
      grid-template-columns: 72px;
      grid-auto-flow: column;
      grid-auto-columns: 72px;
    }
  }

  .SkyLink {
    &:hover,
    &:active,
    &:focus {
      color: var(--text-color-primary);
    }

    transition: none;
  }
}

.CategoryTile {
  display: flex;
  padding: var(--spacing-4x);
  background: transparent;
  color: currentColor;
  height: auto;
  align-items: flex-start;
  position: relative;
  max-width: 117px;

  &_ImageContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include for-medium-up {
      width: 100%;
      height: auto;
      margin-right: 0;
      align-items: center;
    }
  }

  &_Image {
    width: 64px;
    height: auto;
    object-fit: contain;
    transition: all 0.2s ease-in-out;
    @include for-small-down {
      width: var(--spacing-12x);
      max-width: unset;
    }
  }

  &_CategoryName {
    text-align: center;
    font-size: var(--font-size-75);
    margin-top: var(--spacing-2x);
    line-height: 17px;
  }

  &:active .CategoryTile_CategoryName {
    text-decoration: underline;
    text-decoration-thickness: 0.125rem;
  }

  &:hover .CategoryTile_Image {
    transform: scale(1.2);

    @include for-small-down {
      width: var(--spacing-12x);
    }
  }

  &:focus-visible {
    border-color: var(--color-navy-dark);
    border-bottom: none;
  }

  @include for-small-down {
    padding: var(--spacing-2x) var(--spacing-4x);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 72px;

    .CategoryTile_ImageContainer {
      width: 100%;
      max-width: 144px;
      margin-right: 0;
      align-items: center;
    }

    .CategoryTile_CategoryName {
      font-size: var(--font-size-50);
      line-height: 14px;
    }
  }
}
</style>
