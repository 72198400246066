/**
 * Defines default meta values for pages.
 */
export const META = {
  welcome: {
    title: 'Get Your Free Grove Starter Set Today',
    description:
      'Grove Collaborative delivers natural home essentials, including bamboo paper towels, eco-friendly soap and natural laundry detergent from brands you and your family will love.',
    robots: '',
    image:
      'https://images.grove.co/upload/f_auto,fl_progressive,q_auto/v1570129016/meg-bolinas_yfr1vy.jpg',
  },
}

/**
 * Defines the categories used for the CategoryCarousel.
 */
export const GROVE_CATEGORIES = [
  {
    id: 1,
    name: 'Cleaning Essentials',
    srcImage:
      'https://images.grove.co/upload/v1666395229/global/Icons/Category/ic-cleaning-essentials.svg',
    link: '/catalog/?category=cleaning-essentials',
  },
  {
    id: 2,
    name: 'Starter Sets',
    srcImage:
      'https://images.grove.co/upload/v1711140676/global/Icons/Category/ic-starter-set.svg',
    link: '/catalog/?category=starter-sets',
  },
  {
    id: 3,
    name: 'Vitamins & Supplements',
    srcImage:
      'https://images.grove.co/upload/v1667861713/global/Icons/Category/ic-vitamins-and-supplements.svg',
    link: '/catalog/?category=supplements',
  },
  {
    id: 4,
    name: 'Home & Pantry',
    srcImage:
      'https://images.grove.co/upload/v1666395226/global/Icons/Category/ic-home-and-pantry.svg',
    link: '/catalog/?category=home-pantry',
  },
  {
    id: 5,
    name: 'Personal Care',
    srcImage:
      'https://images.grove.co/upload/v1666395229/global/Icons/Category/ic-personal-care.svg',
    link: '/catalog/?category=personal-care',
  },
  {
    id: 6,
    name: 'Pet Supplies',
    srcImage:
      'https://images.grove.co/upload/v1666395229/global/Icons/Category/ic-pet-supplies.svg',
    link: '/catalog/?category=pet',
  },
  {
    id: 7,
    name: 'Health & Wellness',
    srcImage:
      'https://images.grove.co/upload/v1666395229/global/Icons/Category/ic-health-and-wellness.svg',
    link: '/catalog/?category=health-wellness',
  },
  {
    id: 8,
    name: 'Hand Soap',
    srcImage:
      'https://images.grove.co/upload/v1666395225/global/Icons/Category/ic-hand-soap.svg',
    link: '/catalog/?category=hand-soap',
  },
  {
    id: 9,
    name: 'Dish Soap',
    srcImage:
      'https://images.grove.co/upload/v1666395225/global/Icons/Category/ic-dish-soap.svg',
    link: '/catalog/?category=dish-soap',
  },
  {
    id: 10,
    name: 'Storage & Organization',
    srcImage:
      'https://images.grove.co/upload/v1666395226/global/Icons/Category/ic-storage-and-organization.svg',
    link: '/catalog/?category=storage-organization',
  },
  {
    id: 11,
    name: 'Laundry Care',
    srcImage:
      'https://images.grove.co/upload/v1666395228/global/Icons/Category/ic-laundry-care.svg',
    link: '/catalog/?category=laundry',
  },
  {
    id: 12,
    name: 'Paper Products',
    srcImage:
      'https://images.grove.co/upload/v1666395225/global/Icons/Category/ic-paper-products.svg',
    link: '/catalog/?category=household-paper',
  },
]

export const MOBILE_APP_IMAGES = {
  iosImage: 'https://images.grove.co/upload/global/app-store.svg',
  androidImage:
    'https://images.grove.co/upload/global/google-play-badge-vector.svg',
}

/**
 * Defines error messages for user-provided input.
 */
export const ERROR_MESSAGES = {
  INVALID_EMAIL: 'Please enter a valid email address.',
  INELIGIBLE_EMAIL:
    'You are not eligible for this offer. If you are a current customer, please login.',
}

/**
 * Defines offer titles for gift footer
 */
export const OFFER_TITLES = {
  POP: 'Choose a free gift, like the Grove Co. refillable cleaning set or a Mrs. Meyer’s set.',
  NO_POP: 'Get your free Grove earth-friendly cleaning set!',
}

/**
 * Wellness nav constants
 */
export const WELLNESS_ITEM_ID = '221'
export const GROVE_WELLNESS_LOGO_IMAGE =
  'https://images.grove.co/upload/f_auto,fl_progressive,w_560,q_auto/global/Logos/Grove-Wellness-Logo.png'
